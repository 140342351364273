.floatingMenu{
    position: fixed;
    top: 50%;
    left: 40px;
    display: grid;
    transform: translateY(-50%);
    z-index: 999;
    @media screen and (max-width: 768px){
        display: none;
    }
    .fmitem{
        background-color:rgb(8, 114, 214);
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-bottom:20px;
        transition : all 0.2s ease-in-out;
        &:hover{
            transform: scale(1.5);
            background-color:#fff;
            transform: scale(1.5);
            color:rgb(8, 114, 214);
        }
        &+i:hover{
            transform: scale(1.5);
            color:rgb(8, 114, 214);
        }
        i{
            position: relative;
            left:4px;
            color:#fff;
            transform: translate(-50%,-50%);
            font-size: 40px;
            transition : all 0.2s ease-in-out;
            &:hover{
                transform: scale(1.5);
                color:rgb(8, 114, 214);
            }
        }
    }
}

.mobileMenu{
    position: fixed;
    bottom: 0px;
    display: none;
    @media screen and (max-width: 768px){
        display: unset;
    }
    .fmitem{
        background-color:rgb(8, 114, 214);
        transition : all 0.2s ease-in-out;
        border-right: 1px solid #fff;
        border-left: 1px solid #fff;
        &:hover{
            background-color:#fff;
            color:rgb(8, 114, 214);
        }
        &+i:hover{
            color:rgb(8, 114, 214);
        }
        i{
            position: relative;
            left:4px;
            color:#fff;
            transform: translate(-50%,-50%);
            font-size: 40px;
            transition : all 0.2s ease-in-out;
            &:hover{
                color:rgb(8, 114, 214);
            }
        }
    }
}

body{
    @media screen and (max-width: 768px){
        margin-bottom: 100px;
    }
}