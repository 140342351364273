@import url("https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible&display=swap");
* {
  font-family: "Atkinson Hyperlegible", sans-serif;
}

#contact {
  height: 100vh;
  .row {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}
