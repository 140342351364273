@keyframes colorchange {
    0% {color: #4d96d5; bottom: -100px;}
    50% {color: #9200c8; bottom: -110px;}
    100% {color: #4d96d5; bottom: -100px;}
}

.cont-datos{
    height: 100vh;
    .datos{
        position: absolute; 
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        .logo{
            width: 200px;
            margin: 0 auto;
            display: block;
            margin-bottom: 20px;
        }
        h1{
            font-size: 80px;
        }
        .line{
            width: 30%;
            height: 1px;
            background-color: #000;
            margin: 0 auto;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .sociallink{
            img{
                width: 30px;
                transition : all 0.3s ease-in-out;
                &:hover{
                    transform: scale(1.5);
                }
            }
        }
        .morearrow{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -100px;
            font-size: 80px;
            animation-name: colorchange;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
        }
    }
}