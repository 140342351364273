.about{
    background-color: #fafafa;
    padding-top: 40px;
    padding-bottom: 40px;
    p{
        font-size: 20px;
    }
    li{
        list-style: none;
    }
}

.sectionsMargin{
    margin-bottom: 50px;
}

.bottomline{
    border-bottom: 1px solid #e0e0e0;
}

.divideline{
    border-right: 1px solid #e0e0e0;
        @media screen and (max-width: 768px){
            border-right: none;
            border-bottom: 1px solid #e0e0e0;
            margin-bottom: 50px;
        }
}

.timeline{
    width: 1px;
    height: 40px;
    border-left: 1px solid #000;
    position: relative;
    margin: 0 auto;
}